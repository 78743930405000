import locales from './locales'
import type { IntlMiddlewareConfig } from './types'

const config: IntlMiddlewareConfig = {
  locales,
  pathnames: {},
  defaultLocale: 'de',
  localePrefix: 'always', // routes should always include the locale
}

export default config
